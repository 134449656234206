<template>
    <b-card id="food-cost-details" class="mb-0" no-body>
        <b-tabs pills card>
            <b-tab title="Передано без привязки к дате" active>
                <b-row>
                    <b-col class="text-center">
                        <b-button
                            v-show="extra.length > 0"
                            @click="exportExtraToCSV()"
                            variant="success"
                        >
                            Скачать
                        </b-button>
                    </b-col>
                </b-row>

                <b-table class="mb-0" :fields="extraFields" :items="extra">
                    <template #cell(actId)="data">
                        <router-link
                            :to="{name: 'MaterialTransferActUpdate', params: {id: data.item.actId}}"
                            target="_blank"
                        >{{ data.item.actId }}</router-link>
                    </template>
                </b-table>
            </b-tab>
            <b-tab title="Списано инвентаризацией">
                <b-row>
                    <b-col class="text-center">
                        <b-button
                            v-show="debited.length > 0"
                            @click="exportDebitedToCSV()"
                            variant="success"
                        >
                            Скачать
                        </b-button>
                    </b-col>
                </b-row>

                <b-table class="mb-0" :fields="debitedFields" :items="debited">
                    <template #cell(actId)="data">
                        <router-link
                            :to="{name: 'InventoryActUpdate', params: {id: data.item.actId}}"
                            target="_blank"
                        >{{ data.item.actId }}</router-link>
                    </template>
                </b-table>
            </b-tab>
            <b-tab title="Передано с привязкой к дате">
                <b-row>
                    <b-col class="text-center">
                        <b-button
                            v-show="transferred.length > 0"
                            @click="exportTransferredToCSV()"
                            variant="success"
                        >
                            Скачать
                        </b-button>
                    </b-col>
                </b-row>

                <b-table class="mb-0" :fields="transferredFields" :items="transferred">
                    <template #cell(actId)="data">
                        <router-link
                            :to="{name: 'MaterialTransferActUpdate', params: {id: data.item.actId}}"
                            target="_blank"
                        >{{ data.item.actId }}</router-link>
                    </template>
                </b-table>
            </b-tab>
            <b-tab title="Детализация по сырью">
                <b-row>
                    <b-col class="text-center">
                        <b-button
                            v-show="detailed.length > 0"
                            @click="exportDetailedToCSV()"
                            variant="success"
                        >
                            Скачать
                        </b-button>
                    </b-col>
                </b-row>

                <b-table class="mb-0" :fields="detailedFields" :items="detailed" :foot-clone="true">
                    <template #cell(deltaCost)="data">
                        <span v-if="data.item.deltaCost === 0">{{ data.value }}</span>
                        <span
                            v-else-if="data.item.deltaCost < 0"
                            style="color: #ff4444;"
                        >{{ data.value }}</span>
                        <span v-else style="color: #00c851">{{ data.value }}</span>
                    </template>
                    <template #cell(deltaStatus)="data">
                        <span v-if="data.item.deltaCost === 0">по плану</span>
                        <span v-else-if="data.item.deltaCost < 0" style="color: #ff4444;">перевыдача</span>
                        <span v-else style="color: #00c851">недовыдача</span>
                    </template>
                    <template #foot(nomenclature)="data">Итого:</template>
                    <template #foot(oldPlanned)="data">—</template>
                    <template #foot(totalPlanned)="data">—</template>
                    <template #foot(transferred)="data">—</template>
                    <template
                        #foot(totalPlannedCost)="data"
                    >{{ data.field.formatter(detailed.reduce((total, item) => total + item[data.field.key], 0)) }}</template>
                    <template
                        #foot(transferredCost)="data"
                    >{{ data.field.formatter(detailed.reduce((total, item) => total + item[data.field.key], 0)) }}</template>
                    <template
                        #foot(deltaCost)="data"
                    >{{ data.field.formatter(deltaCostSummary(detailed)) }}</template>
                    <template #foot(deltaStatus)="data">
                        <span v-if="deltaCostSummary(detailed) === 0">по плану</span>
                        <span
                            v-else-if="deltaCostSummary(detailed) < 0"
                            style="color: #ff4444;"
                        >перевыдача</span>
                        <span v-else style="color: #00c851">недовыдача</span>
                    </template>
                </b-table>
            </b-tab>
            <b-tab title="Детализация по доп. планам">
                <b-row>
                    <b-col class="text-center">
                        <b-button
                            v-show="extraPlanned.length > 0"
                            @click="exportExtraPlannedToCSV()"
                            variant="success"
                        >
                            Скачать
                        </b-button>
                    </b-col>
                </b-row>

                <b-table
                    class="mb-0"
                    :fixed="true"
                    :fields="extraPlannedFields"
                    :items="extraPlanned"
                    :foot-clone="true"
                >
                    <template #foot(nomenclature)="data">Итого:</template>
                    <template #foot(reason)="data">—</template>
                    <template
                        #foot(cost)="data"
                    >{{ data.field.formatter(extraPlanned.reduce((total, item) => total + item[data.field.key], 0)) }}</template>
                    <template #foot(actions)="data">&nbsp;</template>
                    <template #cell(actions)="row">
                        <b-button
                            size="sm"
                            variant="light"
                            @click="row.toggleDetails"
                        >{{ row.detailsShowing ? 'Скрыть' : 'Показать'}} детали</b-button>
                    </template>
                    <template #row-details="row">
                        <b-card class="mb-0">
                            <div
                                class="row"
                                v-for="(stuffName, stuffIndex) in row.item['stuffNames']"
                                v-bind:key="stuffIndex"
                            >
                                <div class="col col-md-3">{{ stuffName }}</div>
                                <div
                                    class="col col-md-3 text-center"
                                >{{ row.item['stuffCounts'][stuffIndex] + row.item['stuffUnits'][stuffIndex] }}</div>
                                <div
                                    class="col col-md-3 text-center"
                                >{{ stuffCostFormatter(row.item['stuffCosts'][stuffIndex]) }}</div>
                                <div class="col col-md-3 text-center">&nbsp;</div>
                            </div>
                        </b-card>
                    </template>
                </b-table>
            </b-tab>
        </b-tabs>
    </b-card>
</template>

<script>
    import moment from "moment"
    import { FOOD_COST_ENDPOINT } from "../../utils/endpoints"
    import CSV from "../../utils/csv"

    export default {
        name: "FoodCostDetails",
        props: {
            startDate: {
                type: String,
                required: true,
            },
            endDate: {
                type: String,
                required: true,
            },
        },
        data() {
            return {
                moment,
                extraFields: [
                    {
                        key: "actDate",
                        label: "Дата",
                        thClass: "text-center",
                        tdClass: "text-center",
                    },
                    {
                        key: "nomenclature",
                        label: "Номенклатура",
                        thClass: "text-left",
                        tdClass: "text-left",
                    },
                    {
                        key: "storageFrom",
                        label: "Откуда",
                        thClass: "text-center",
                        tdClass: "text-center",
                    },
                    {
                        key: "storageTo",
                        label: "Куда",
                        thClass: "text-center",
                        tdClass: "text-center",
                    },
                    {
                        key: "count",
                        label: "Кол-во",
                        thClass: "text-center",
                        tdClass: "text-center",
                        formatter(value, key, item) {
                            return value.toLocaleString('ru-RU', { useGrouping: false }) + item.unit
                        },
                    },
                    {
                        key: "cost",
                        label: "Стоимость,  ₽",
                        thClass: "text-center",
                        tdClass: "text-center",
                        formatter(value) {
                            return value.toLocaleString('ru-RU', { useGrouping: false })
                        },
                    },
                    {
                        key: "actId",
                        label: "Акт",
                        thClass: "text-center",
                        tdClass: "text-center",
                    },
                    {
                        key: "author",
                        label: "Автор",
                        thClass: "text-center",
                        tdClass: "text-center",
                    },
                ],
                debitedFields: [
                    {
                        key: "actDate",
                        label: "Дата",
                        thClass: "text-center",
                        tdClass: "text-center",
                    },
                    {
                        key: "nomenclature",
                        label: "Номенклатура",
                        thClass: "text-left",
                        tdClass: "text-left",
                    },
                    {
                        key: "storageFrom",
                        label: "Откуда",
                        thClass: "text-center",
                        tdClass: "text-center",
                    },
                    {
                        key: "count",
                        label: "Кол-во",
                        thClass: "text-center",
                        tdClass: "text-center",
                        formatter(value, key, item) {
                            return value.toLocaleString('ru-RU', { useGrouping: false }) + item.unit
                        },
                    },
                    {
                        key: "cost",
                        label: "Стоимость, ₽",
                        thClass: "text-center",
                        tdClass: "text-center",
                        formatter(value) {
                            return value.toLocaleString('ru-RU', { useGrouping: false })
                        },
                    },
                    {
                        key: "actId",
                        label: "Акт",
                        thClass: "text-center",
                        tdClass: "text-center",
                    },
                    {
                        key: "author",
                        label: "Автор",
                        thClass: "text-center",
                        tdClass: "text-center",
                    },
                ],
                transferredFields: [
                    {
                        key: "actDate",
                        label: "Дата",
                        thClass: "text-center",
                        tdClass: "text-center",
                    },
                    {
                        key: "nomenclature",
                        label: "Номенклатура",
                        thClass: "text-left",
                        tdClass: "text-left",
                    },
                    {
                        key: "storageFrom",
                        label: "Откуда",
                        thClass: "text-center",
                        tdClass: "text-center",
                    },
                    {
                        key: "storageTo",
                        label: "Куда",
                        thClass: "text-center",
                        tdClass: "text-center",
                    },
                    {
                        key: "count",
                        label: "Кол-во",
                        thClass: "text-center",
                        tdClass: "text-center",
                        formatter(value, key, item) {
                            return value.toLocaleString('ru-RU', { useGrouping: false }) + item.unit
                        },
                    },
                    {
                        key: "cost",
                        label: "Стоимость, ₽",
                        thClass: "text-center",
                        tdClass: "text-center",
                        formatter(value) {
                            return value.toLocaleString('ru-RU', { useGrouping: false })
                        },
                    },
                    {
                        key: "actId",
                        label: "Акт",
                        thClass: "text-center",
                        tdClass: "text-center",
                    },
                    {
                        key: "author",
                        label: "Автор",
                        thClass: "text-center",
                        tdClass: "text-center",
                    },
                ],
                detailedFields: [
                    {
                        key: "nomenclature",
                        label: "Номенклатура",
                        thClass: "text-left",
                        tdClass: "text-left",
                    },
                    {
                        key: "oldPlanned",
                        label: "Старый план",
                        thClass: "text-center",
                        tdClass: "text-center",
                        formatter(value, key, item) {
                            return value.toLocaleString('ru-RU', { useGrouping: false }) + item.unit
                        },
                    },
                    {
                        key: "totalPlanned",
                        label: "Новый план",
                        thClass: "text-center",
                        tdClass: "text-center",
                        formatter(value, key, item) {
                            return value.toLocaleString('ru-RU', { useGrouping: false }) + item.unit
                        },
                    },
                    {
                        key: "transferred",
                        label: "Факт выдачи",
                        thClass: "text-center",
                        tdClass: "text-center",
                        formatter(value, key, item) {
                            return value.toLocaleString('ru-RU', { useGrouping: false }) + item.unit
                        },
                    },
                    {
                        key: "totalPlannedCost",
                        label: "План расхода, ₽",
                        thClass: "text-center",
                        tdClass: "text-center",
                        formatter(value) {
                            return value.toLocaleString('ru-RU', { useGrouping: false })
                        },
                    },
                    {
                        key: "transferredCost",
                        label: "Факт расхода, ₽",
                        thClass: "text-center",
                        tdClass: "text-center",
                        formatter(value) {
                            return value.toLocaleString('ru-RU', { useGrouping: false })
                        },
                    },
                    {
                        key: "deltaCost",
                        label: "Разница расхода, ₽",
                        thClass: "text-center",
                        tdClass: "text-center",
                        formatter(value) {
                            return Math.abs(value).toLocaleString('ru-RU', { useGrouping: false })
                        },
                    },
                    {
                        key: "deltaStatus",
                        label: "Статус",
                        thClass: "text-center",
                        tdClass: "text-center",
                    },
                ],
                extraPlannedFields: [
                    {
                        key: "nomenclature",
                        label: "Номенклатура плана",
                        thClass: "text-left",
                        tdClass: "text-left",
                    },
                    {
                        key: "reason",
                        label: "Причина создания плана",
                        thClass: "text-center",
                        tdClass: "text-center",
                    },
                    {
                        key: "cost",
                        label: "Сумма плановых затрат, ₽",
                        thClass: "text-center",
                        tdClass: "text-center",
                        formatter(value) {
                            return value.toLocaleString('ru-RU', { useGrouping: false })
                        },
                    },
                    {
                        key: "actions",
                        label: "Действия",
                        thClass: "text-center",
                        tdClass: "text-center",
                    },
                ],
                extra: [],
                debited: [],
                transferred: [],
                detailed: [],
                extraPlanned: [],
            }
        },
        methods: {
            load() {
                this.$http
                    .get(FOOD_COST_ENDPOINT + "/details", {
                        params: {
                            startDate: moment(this.startDate, "DD.MM.YYYY").format("YYYY-MM-DD"),
                            endDate: moment(this.endDate, "DD.MM.YYYY").format("YYYY-MM-DD")
                        },
                    })
                    .then((response) => response.data.data)
                    .then(({ extra, debited, transferred, detailed, extraPlanned }) => {
                        this.extra = extra
                        this.debited = debited
                        this.transferred = transferred
                        this.detailed = detailed
                        this.extraPlanned = extraPlanned
                    })
                    .catch((response) => {
                        this.$toast.error("Не удалось загрузить детали фудкоста")
                    })
            },
            deltaCostSummary(items) {
                return items.reduce((total, item) => total + item["deltaCost"], 0)
            },
            stuffCostFormatter(value) {
                return value.toLocaleString('ru-RU', { useGrouping: false })
            },
            exportExtraToCSV() {
                const csvHeader = this.extraFields.map((field) => field.label).join(';') + "\n"

                let csvContent = ''
                this.extra.forEach((item) => {
                    csvContent += [
                        item.actDate,
                        item.nomenclature,
                        item.storageFrom,
                        item.storageTo,
                        item.count.toLocaleString('ru-RU', {useGrouping: false}),
                        item.cost.toLocaleString('ru-RU', {useGrouping: false}),
                        item.actId,
                        item.author
                    ].join(';') + "\n"
                })

                CSV.download(
                    csvHeader + csvContent,
                    `Отчет переданного без привязки к дате от ${this.startDate} до ${this.endDate}`
                )
            },
            exportDebitedToCSV() {
                const csvHeader = this.debitedFields.map((field) => field.label).join(';') + "\n"

                let csvContent = ''
                this.debited.forEach((item) => {
                    csvContent += [
                        item.actDate,
                        item.nomenclature,
                        item.storageFrom,
                        item.count.toLocaleString('ru-RU', {useGrouping: false}),
                        item.cost.toLocaleString('ru-RU', {useGrouping: false}),
                        item.actId,
                        item.author
                    ].join(';') + "\n"
                })

                CSV.download(
                    csvHeader + csvContent,
                    `Отчет списанного инвентаризацией от ${this.startDate} до ${this.endDate}`
                )
            },
            exportTransferredToCSV() {
                const csvHeader = this.transferredFields.map((field) => field.label).join(';') + "\n"

                let csvContent = ''
                this.transferred.forEach((item) => {
                    csvContent += [
                        item.actDate,
                        item.nomenclature,
                        item.storageFrom,
                        item.storageTo,
                        item.count.toLocaleString('ru-RU', {useGrouping: false}) + item.unit,
                        item.cost.toLocaleString('ru-RU', {useGrouping: false}),
                        item.actId,
                        item.author
                    ].join(';') + "\n"
                })

                CSV.download(
                    csvHeader + csvContent,
                    `Отчет переданного с привязкой к дате от ${this.startDate} до ${this.endDate}`
                )
            },
            exportDetailedToCSV() {
                const csvHeader = this.detailedFields.map((field) => field.label).join(';') + "\n"

                const getStatusByDeltaCost = (deltaCost) => {
                    let deltaStatus = 'недовыдача'
                    if (deltaCost === 0) {
                        deltaStatus = 'по плану'
                    } else if (deltaCost < 0) {
                        deltaStatus = 'перевыдача'
                    }

                    return deltaStatus
                }

                let csvContent = ''
                this.detailed.forEach((item) => {
                    csvContent += [
                        item.nomenclature,
                        item.oldPlanned.toLocaleString('ru-RU', { useGrouping: false }) + item.unit,
                        item.totalPlanned.toLocaleString('ru-RU', { useGrouping: false }) + item.unit,
                        item.transferred.toLocaleString('ru-RU', { useGrouping: false }) + item.unit,
                        item.totalPlannedCost.toLocaleString('ru-RU', { useGrouping: false }),
                        item.transferredCost.toLocaleString('ru-RU', { useGrouping: false }),
                        Math.abs(item.deltaCost).toLocaleString('ru-RU', { useGrouping: false }),
                        getStatusByDeltaCost(item.deltaCost)
                    ].join(';') + "\n"
                })

                CSV.download(
                    csvHeader + csvContent,
                    `Отчет детализации по сырью от ${this.startDate} до ${this.endDate}`
                )
            },
            exportExtraPlannedToCSV(v) {
                const csvHeader = this.extraPlannedFields.map((field) => {
                    if (field.key !== 'actions') {
                        return field.label
                    }
                }).join(';') + 'Сырье;' + "\n"

                let csvContent = ''
                this.extraPlanned.forEach((item) => {
                    csvContent += [
                        item.nomenclature,
                        item.reason,
                        item.cost.toLocaleString('ru-RU', { useGrouping: false })
                    ].join(';') + "\n"

                    if (item.stuffNames
                        && item.stuffCounts
                        && item.stuffUnits
                        && item.stuffCosts
                    ) {
                        item.stuffNames.forEach((stuffName, stuffIndex) => {
                            csvContent += [
                                '',
                                '',
                                '',
                                stuffName,
                                item.stuffCounts[stuffIndex] + item.stuffUnits[stuffIndex],
                                item.stuffCosts[stuffIndex]
                            ].join(';') + "\n"
                        })
                    }
                })

                CSV.download(
                    csvHeader + csvContent,
                    `Отчет детализации по доп. планам от ${this.startDate} до ${this.endDate}`
                )
            }
        },
        mounted() {
            this.load()
        },
    }
</script>

<style scoped>
    .card-body {
        padding: 0 !important;
    }
</style>

<style>
    #food-cost-details tr.b-table-details > td {
        padding-top: 0 !important;
        padding-left: 0.5rem !important;
        padding-right: 0.5rem !important;
        padding-bottom: 0.5rem !important;
    }

    #food-cost-details tr.b-table-details .card-body {
        padding: 0.25rem !important;
    }
</style>
