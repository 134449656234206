<template>
    <b-card no-body>
        <b-card-header>
            <div class="row">
                <div class="col col-md-3">
                    <date-time-picker
                        label="С"
                        locale="ru"
                        id="startDate"
                        :format="null"
                        input-size="sm"
                        position="bottom"
                        :no-label="true"
                        :no-header="true"
                        :no-button="true"
                        :no-clear-button="true"
                        :auto-close="true"
                        :only-date="true"
                        v-model="startDate"
                        formatted="DD.MM.YYYY"
                        :max-date="endDate ? moment(endDate).format('YYYY-MM-DD') : null"
                    />
                </div>
                <div class="col col-md-3">
                    <date-time-picker
                        label="По"
                        locale="ru"
                        id="'date-to"
                        :format="null"
                        input-size="sm"
                        position="bottom"
                        :no-label="true"
                        :no-header="true"
                        :no-button="true"
                        :no-clear-button="true"
                        :auto-close="true"
                        :only-date="true"
                        v-model="endDate"
                        formatted="DD.MM.YYYY"
                        :min-date="startDate ? moment(startDate).format('YYYY-MM-DD') : null"
                    />
                </div>
                <div class="col">
                    <b-button variant="primary" @click="load()" class="mr-1">Обновить</b-button>
                    <b-button v-show="items.length > 0" @click="exportToCSV()" variant="success" >Скачать</b-button>
                </div>
            </div>
        </b-card-header>
        <b-card-body class="card-body">
            <bar-chart
                id="bar"
                xkey="date"
                :data="items"
                :labels="['Передано', 'Доп. передано', 'Списано', 'По плану']"
                :ykeys="['totalTransferCost', 'totalExtraCost', 'totalDebitCost', 'totalPlanCost']"
                :bar-colors="['#ff4444', '#8d6e63', '#ffbb33', '#33b5e5']"
                grid-text-weight="bold"
                class="mt-3 mb-3"
                resize="true"
                grid="true"
            />
        </b-card-body>
        <b-card-body class="card-body" v-show="items.length > 0">
            <b-table class="mb-0" :fields="fields" :items="items" :foot-clone="true" @row-clicked="onRowClicked">
                <template #cell(deviation)="data">
                    <span v-if="data.item.totalShipped === 0 || data.item.totalPacked === 0">—</span>
                    <span
                        v-else
                        :style="{ color: data.value < 0 ? '#ff4444' : '#00c851' }"
                    >
                        {{ parseFloat((data.value * 100).toFixed(2)).toLocaleString('ru-RU') + "%" }}
                    </span>
                </template>
                <template #foot(date)>
                    <span ref="FOOT_date">Итого:</span>
                </template>
                <template #foot(totalTransferCost)="data">
                    <span
                        ref="FOOT_totalTransferCost"
                    >{{ data.field.formatter(items.reduce((total, item) => total + item[data.field.key], 0)) }}</span>
                </template>
                <template #foot(totalExtraCost)="data">
                    <span
                        ref="FOOT_totalExtraCost"
                    >{{ data.field.formatter(items.reduce((total, item) => total + item[data.field.key], 0)) }}</span>
                </template>
                <template #foot(totalDebitCost)="data">
                    <span
                        ref="FOOT_totalDebitCost"
                    >{{ data.field.formatter(items.reduce((total, item) => total + item[data.field.key], 0)) }}</span>
                </template>
                <template #foot(totalFactCost)="data">
                    <span
                        ref="FOOT_totalFactCost"
                    >{{ data.field.formatter(items.reduce((total, item) => total + item[data.field.key], 0)) }}</span>
                </template>
                <template #foot(totalPlanCost)="data">
                    <span
                        ref="FOOT_totalPlanCost"
                    >{{ data.field.formatter(items.reduce((total, item) => total + item[data.field.key], 0)) }}</span>
                </template>
                <template #foot(totalExtraPlanCost)="data">
                    <span
                        ref="FOOT_totalExtraPlanCost"
                    >{{ data.field.formatter(items.reduce((total, item) => total + item[data.field.key], 0)) }}</span>
                </template>
                <template #foot(totalShipped)="data">
                    <span
                        ref="FOOT_totalShipped"
                    >{{ data.field.formatter(totalShippedSummary(items), 'totalShipped', { totalPacked: totalPackedSummary(items) }) }}</span>
                </template>
                <template #foot(packFactCost)="data">
                    <span
                        ref="FOOT_packFactCost"
                    >{{ data.field.formatter(packFactCostSummary(items), 'packFactCost', { totalShipped: totalShippedSummary(items) }) }}</span>
                </template>
                <template #foot(packPlanCost)="data">
                    <span
                        ref="FOOT_packPlanCost"
                    >{{ data.field.formatter(packPlanCostSummary(items, 'packPlanCost', { totalPacked: totalPackedSummary(items) })) }}</span>
                </template>

                <template #foot(deviation)="data">
                    <span ref="FOOT_deviation" >
                    <span
                        v-if="totalShippedSummary(items) === 0 || totalPackedSummary(items) === 0"
                    >—</span>
                    <span
                        v-else
                        :style="{ color: deviationSummary(items) < 0 ? '#ff4444' : '#00c851' }"
                    >
                        {{ parseFloat((deviationSummary(items) * 100).toFixed(2)).toLocaleString('ru-RU') + "%" }}
                    </span>
                </span>
                </template>
            </b-table>
        </b-card-body>
    </b-card>
</template>

<script>
    import moment from "moment"
    import { BarChart } from "vue-morris"
    import DateTimePicker from "vue-ctk-date-time-picker"
    import FoodCostDetails from './FoodCostDetails'
    import { FOOD_COST_ENDPOINT } from "../../utils/endpoints"
    import CSV from "../../utils/csv"

    const DETAILS_MODAL_PARAMS = {
        width: 1400,
        height: 'auto',
        adaptive: true,
        scrollable: true
    }

    export default {
        name: "FoodCost",
        components: { DateTimePicker, BarChart },
        data() {
            return {
                moment,
                startDate: moment().subtract(6, "days"),
                endDate: moment(),
                fields: [
                    {
                        key: "date",
                        label: "Дата",
                        thClass: "text-center",
                        tdClass: "text-center"
                    },
                    {
                        key: "totalTransferCost",
                        label: "Передано, ₽",
                        thClass: "text-center",
                        tdClass: "text-center",
                        formatter(value) {
                            return value.toLocaleString('ru-RU', { useGrouping: false })
                        }
                    },
                    {
                        key: "totalExtraCost",
                        label: "Доп. передано, ₽",
                        thClass: "text-center",
                        tdClass: "text-center",
                        formatter(value) {
                            return value.toLocaleString('ru-RU', { useGrouping: false })
                        }
                    },
                    {
                        key: "totalDebitCost",
                        label: "Списано, ₽",
                        thClass: "text-center",
                        tdClass: "text-center",
                        formatter(value) {
                            return value.toLocaleString('ru-RU', { useGrouping: false })
                        }
                    },
                    {
                        key: "totalFactCost",
                        label: "Факт. стоимость, ₽",
                        thClass: "text-center",
                        tdClass: "text-center",
                        formatter(value) {
                            return value.toLocaleString('ru-RU', { useGrouping: false })
                        }
                    },
                    {
                        key: "totalPlanCost",
                        label: "План. стоимость, ₽",
                        thClass: "text-center",
                        tdClass: "text-center",
                        formatter(value) {
                            return value.toLocaleString('ru-RU', { useGrouping: false })
                        }
                    },
                    {
                        key: "totalExtraPlanCost",
                        label: "Доп. выпуск, ₽",
                        thClass: "text-center",
                        tdClass: "text-center",
                        formatter(value) {
                            return value.toLocaleString('ru-RU', { useGrouping: false })
                        }
                    },
                    {
                        key: "totalShipped",
                        label: "Отгружено лотков",
                        thClass: "text-center",
                        tdClass: "text-center",
                        formatter(value, key, item) {
                            return value.toLocaleString('ru-RU', { useGrouping: false }) + " / " +
                                item.totalPacked.toLocaleString('ru-RU', { useGrouping: false })
                        }
                    },
                    {
                        key: "packFactCost",
                        label: "Факт. стоимость лотка, ₽",
                        thClass: "text-center",
                        tdClass: "text-center",
                        formatter(value, key, item) {
                            if (item && item.totalShipped === 0) {
                                return "—"
                            }
                            return parseFloat(value.toFixed(2)).toLocaleString('ru-RU', { useGrouping: false })
                        }
                    },
                    {
                        key: "packPlanCost",
                        label: "План. стоимость лотка, ₽",
                        thClass: "text-center",
                        tdClass: "text-center",
                        formatter(value, key, item) {
                            if (item && item.totalPacked === 0) {
                                return "—"
                            }
                            return parseFloat(value.toFixed(2)).toLocaleString('ru-RU', { useGrouping: false })
                        }
                    },
                    {
                        key: "deviation",
                        label: "Отклонение",
                        thClass: "text-center",
                        tdClass: "text-center"
                    }
                ],
                items: []
            }
        },
        methods: {
            load() {
                this.$http
                    .get(FOOD_COST_ENDPOINT, {
                        params: {
                            startDate: moment(this.startDate).format("YYYY-MM-DD"),
                            endDate: moment(this.endDate).format("YYYY-MM-DD")
                        }
                    })
                    .then(response => response.data.data)
                    .then(items => {
                        this.items = items
                    })
                    .catch(response => {
                        this.$toast.error("Не удалось загрузить фудкост")
                    })
            },
            totalShippedSummary(items) {
                return items.reduce((total, item) => total + item["totalShipped"], 0)
            },
            totalPackedSummary(items) {
                return items.reduce((total, item) => total + item["totalPacked"], 0)
            },
            packFactCostSummary(items) {
                const totalShippedSummary = this.totalShippedSummary(items)
                if (totalShippedSummary === 0) {
                    return 0
                }
                const totalFactCostSummary = items.reduce((total, item) => {
                    if (item["totalShipped"] === 0) {
                        return total
                    } else {
                        return total + item["totalFactCost"]
                    }
                }, 0)
                return totalFactCostSummary / totalShippedSummary
            },
            packPlanCostSummary(items) {
                const totalPackedSummary = this.totalPackedSummary(items)
                if (totalPackedSummary === 0) {
                    return 0
                }
                const totalPlanCostSummary = items.reduce((total, item) => {
                    if (item["totalPacked"] === 0) {
                        return total
                    } else {
                        return total + item["totalPlanCost"]
                    }
                }, 0)
                return totalPlanCostSummary / totalPackedSummary
            },
            deviationSummary(items) {
                const packFactCostSummary = this.packFactCostSummary(items)
                const packPlanCostSummary = this.packPlanCostSummary(items)
                if (packPlanCostSummary === 0) {
                    return 0
                }
                return (packPlanCostSummary - packFactCostSummary) / packPlanCostSummary
            },
            onRowClicked({ date }) {
                this.$modal.show(FoodCostDetails, { startDate: date, endDate: date }, DETAILS_MODAL_PARAMS);
            },
            onSummaryClicked() {
                this.$modal.show(FoodCostDetails, {
                    startDate: moment(this.startDate).format("DD.MM.YYYY"),
                    endDate: moment(this.endDate).format("DD.MM.YYYY")
                }, DETAILS_MODAL_PARAMS);
            },
            exportToCSV() {
                const csvHeader = this.fields.map((field) => field.label).join(';') + "\n"

                let csvContent = ''
                this.items.forEach((item) => {
                    csvContent += [
                        item.date,
                        item.totalTransferCost,
                        item.totalExtraCost,
                        item.totalDebitCost,
                        item.totalFactCost,
                        item.totalPlanCost,
                        item.totalExtraPlanCost,
                        item.totalShipped + ' / ' + item.totalPacked,
                        item.totalShipped === 0
                            ? ''
                            : parseFloat(item.packFactCost.toFixed(2)).toLocaleString('ru-RU', { useGrouping: false }),
                        item.totalPacked === 0
                            ? ''
                            : parseFloat(item.packPlanCost.toFixed(2)).toLocaleString('ru-RU', { useGrouping: false }),
                        item.totalShipped === 0 || item.totalPacked === 0
                            ? ''
                            : parseFloat((item.deviation * 100).toFixed(2)).toLocaleString('ru-RU') + "%"
                    ].join(';') + "\n"
                })

                CSV.download(
                    csvHeader + csvContent,
                    `Отчет фудкоста от ${moment(this.startDate).format('DD.MM.YYYY')} до ${moment(this.endDate).format('DD.MM.YYYY')}`
                )
            }
        },
        mounted() {
            this.load()

            this.$refs['FOOT_date'].parentElement.addEventListener('click', this.onSummaryClicked, false)
            this.$refs['FOOT_totalTransferCost'].parentElement.addEventListener('click', this.onSummaryClicked, false)
            this.$refs['FOOT_totalExtraCost'].parentElement.addEventListener('click', this.onSummaryClicked, false)
            this.$refs['FOOT_totalDebitCost'].parentElement.addEventListener('click', this.onSummaryClicked, false)
            this.$refs['FOOT_totalFactCost'].parentElement.addEventListener('click', this.onSummaryClicked, false)
            this.$refs['FOOT_totalPlanCost'].parentElement.addEventListener('click', this.onSummaryClicked, false)
            this.$refs['FOOT_totalExtraPlanCost'].parentElement.addEventListener('click', this.onSummaryClicked, false)
            this.$refs['FOOT_totalShipped'].parentElement.addEventListener('click', this.onSummaryClicked, false)
            this.$refs['FOOT_packFactCost'].parentElement.addEventListener('click', this.onSummaryClicked, false)
            this.$refs['FOOT_packPlanCost'].parentElement.addEventListener('click', this.onSummaryClicked, false)
            this.$refs['FOOT_deviation'].parentElement.addEventListener('click', this.onSummaryClicked, false)
        }
    }
</script>

<style scoped>
    .card-body {
        padding: 0 !important;
    }
</style>

<style>
    .morris-hover {
        z-index: 998;
    }
</style>
